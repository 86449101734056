import { cn } from "@/lib/utils.ts";
import { motion } from "framer-motion";
import identity from "lodash-es/identity.js";
import { type RefObject, useEffect, useMemo, useRef } from "react";
import { mergeRefs } from "react-merge-refs";
import { useControlField, useField } from "remix-validated-form";
import { TriggerAnimation } from "~zipdeal-ui/animations/TriggerAnimation.tsx";
import { useBrand } from "~zipdeal-ui/contexts-and-providers/BrandProvider.tsx";
import { ZD_ERRORS, ZD_INPUT } from "~zipdeal-ui/globalColors.ts";
import { useValidatorField } from "~zipdeal-ui/hooks/useValidatorField.tsx";
import { ErrorMessage } from "~zipdeal-ui/inputs/common/ErrorMessage.tsx";
import { ThreeDotsPulse } from "~zipdeal-ui/spinners/ThreeDotPulse.tsx";
import { useZipTipStore } from "~zipdeal-ui/stores/zipTipsStore.ts";
import { parseNumber } from "~zipdeal-ui/utilities/ParseNumber.ts";
import { valueAsDateFn } from "~zipdeal-ui/utilities/ValueAsDateFn.tsx";
import { emptyNormalizeFn } from "~zipdeal-ui/utilities/stables.ts";
import { getPlaceholder } from "~zipdeal-ui/utilities/utils.ts";
import { ZipTip } from "./components/ZipTip.tsx";

export interface TextInputProps {
	name: string;
	ref?: RefObject<HTMLInputElement>;
	errorElement?: string;
	defaultValue?: string | number;
	autoComplete?: any;
	label?: React.ReactNode | null;
	type?:
		| "text"
		| "textarea"
		| "number"
		| "email"
		| "password"
		| "search"
		| "tel";
	valueAs?: "text" | "number" | "date";
	description?: React.ReactNode | null;
	placeholder?: React.ReactNode | null;
	afterDescription?: React.ReactNode | null;
	zipTipTools?: {
		zipTip?: string | null;
		loadZipTip?: (name: string) => void;
	};
	maximumLength?: number;
	inError?: boolean;
	normalize?: any;
	disabled?: boolean;
	textColor?: string;
	rightGroup?: React.ReactNode | null;
	borderColor?: string;
	formId: string;
	inline?: boolean;
	loading?: boolean;
	autoFocus?: boolean;
	autoCapitalize?: string;
	autoCorrect?: string;
	confirmationBorder?: boolean;
	inputProps?: any;
	value?: string;
	id?: string;
	onChange?: (...args: any[]) => void;
	afterChange?: Dispatch<string | null | undefined | number>;
	translation?: Function;
	stringContext?: any;
	eventObjectContext?: any;
	labelClassname?: string;
	inputClassName?: string;
	inputWrapperClassname?: string;
	className?: string;
	displaysErrors?: boolean;
}

/**
 * ZipTextInput component.
 *
 * @component
 *
 * @param {string} name - The name attribute of the input.
 * @param {string} formId - The form ID this input belongs to.
 * @param {string} [label] - The optional label text.
 * @param {'text'|'search'|'tel'} [type='text'] - The optional type of input.
 * @param {number} [width] - The optional width of the input.
 * @param {ReactNode} [placeholder] - The optional placeholder text.
 * @param {ReactNode} [description] - The optional description text.
 * @param {boolean} [disabled=false] - Whether the input is optionally disabled.
 * @param {boolean} [inError=false] - Whether to optionally show the input in an error state.
 * @param {ReactNode} [errorElement] - Optional error element to display on error.
 * @param {Object} [inputProps] - Optional additional props for the <input> element.
 * @param {Function} [normalize] - Optional normalizer function for formatting input value.
 * @param {Function} [afterChange] - Optional callback after input value changes.
 *
 * @returns {React.ReactElement} The ZipTextInput component.
 */

const baseClassName = "text-base w-full";
const labelBaseClassName = cn(
	"font-regular flex gap-x-1 tracking-wide",

	ZD_INPUT.LABEL,
);
const inputBaseClassName = cn(
	"text-base py-3 px-4 flex-grow block w-full appearance-none focus:outline-none rounded-l leading-tight",
	ZD_INPUT.TEXT,
	ZD_INPUT.BACKGROUND,
);
export const ZipTextInput = ({
	name,
	formId,
	ref,
	afterDescription = undefined,
	autoComplete = "invalidvaluethatchromedoesntknow",
	borderColor = cn("border-2", ZD_INPUT.BORDER),
	className = "",
	confirmationBorder = false,
	description = undefined,
	disabled = false,
	inError = false,
	errorElement = undefined,
	inline = false,
	inputProps = {},
	inputWrapperClassname = "",
	label = undefined,
	zipTipTools = undefined,
	loading = false,
	maximumLength = undefined,
	normalize = emptyNormalizeFn,
	placeholder = undefined,
	rightGroup = undefined,
	labelClassname = "",
	inputClassName = "",
	type = "text",
	valueAs = "text",
	autoFocus = false,
	afterChange = undefined,
	autoCapitalize = "off",
	autoCorrect = "off",
	value = undefined,
	onChange = undefined,
	id = undefined,
	translation = undefined,
	stringContext = undefined,
	eventObjectContext = {},
	displaysErrors = true,
}: TextInputProps) => {
	const { brand } = useBrand();
	const { error, getInputProps, clearError } = useField(name, {
		formId: formId || "undetermined-form",
	});
	const [_value, _setValue] = useControlField<string>(
		name,
		formId || "undetermined-form",
	);
	const isFirstFocus = useRef(true);
	const {
		setValidatorValue,
		error: errorValidation,
		validatedInputEl,
	} = useValidatorField<string>(name, formId);

	const _inError = inError || !!error;
	const _errorElement = errorElement || error;

	const valueConvertorFn = useMemo(() => {
		switch (valueAs) {
			case "number":
				return parseNumber;
			case "date":
				return valueAsDateFn;
			default:
				return identity;
		}
	}, [valueAs]);

	/* This is for controlled inputs */
	useEffect(() => {
		if (value !== undefined) {
			_setValue(value);
			afterChange?.(value);
		}
	}, [_setValue, afterChange, setValidatorValue, value]);

	useEffect(() => {
		// console.log(`UPDATING VALIDATOR - VALUE CHANGED`, name, _value);
		setValidatorValue(_value ?? "");
	}, [_value, name, setValidatorValue]);

	const _inputProps: any = getInputProps({
		type: type,
		id: id || name,
		autoComplete,
		autoFocus,
		autoCapitalize,
		autoCorrect,
		disabled,
		maxLength: maximumLength,
		"data-testid": name,
		value: _value ?? "",
		placeholder: getPlaceholder(placeholder, null),
		onChange: onChange
			? (event) => {
					const { value } = event.target;
					const normalizedValue = valueConvertorFn(normalize(value, _value));
					const eventObject = {
						event,
						name,
						formId,
						normalizedValue,
						_setValue,
						setValidatorValue,
						afterChange,
						...eventObjectContext,
					};

					onChange(eventObject);
				}
			: (event) => {
					// event.preventDefault();
					const { value } = event.target;
					const normalizedValue = valueConvertorFn(normalize(value, _value));
					_setValue(normalizedValue);
					setValidatorValue(normalizedValue);
					afterChange?.(normalizedValue);
					return normalizedValue;
				},
	});

	const zipTips = useZipTipStore((state) => state.zipTips);
	const hasZipTip = zipTips?.some((t) => t.field === name);

	let inputBorder = borderColor;
	if (confirmationBorder && _value) {
		inputBorder = `border-2 border-accent-${brand}/75`;
	}

	return (
		<TriggerAnimation
			initialState={{ scaleX: 1 }}
			getVariant={({ width, defaultTriggeredVariant }) => {
				const originalWidth = width; // Replace this with the actual original width of the element
				const maxScale = (originalWidth + 4) / originalWidth;
				const minScale = (originalWidth - 3) / originalWidth;

				return {
					scaleX: [1, maxScale, minScale, 1],
					// scaleY: [1, maxScale, minScale, 1],
				};
			}}
		>
			{({
				triggerAnimation,
				animationProps,
				ref: animationRef,
				triggeredVariant,
			}) => {
				return (
					<div
						className={cn({
							[baseClassName]: true,
							relative: true,
							[className]: !!className,
						})}
					>
						{label && (
							<label
								className={cn({
									[labelBaseClassName]: true,
									"mb-2": !description && !inline,
									[labelClassname]: !!labelClassname,
									[ZD_ERRORS.LABEL]: _inError,
								})}
								htmlFor={name}
							>
								{label}
								<ZipTip
									hasZipTip={hasZipTip}
									loadZipTip={() => zipTipTools?.loadZipTip?.(name)}
									zipTip={zipTipTools?.zipTip}
								/>
							</label>
						)}
						{description && (
							<div
								className={`block text-sm font-thin tracking-wide ${ZD_INPUT.TEXT} mb-2`}
							>
								{description}
							</div>
						)}

						<>
							{type !== "textarea" ? (
								<div
									className={cn({
										"relative inline-flex w-full": true,
										[inputWrapperClassname]: !!inputWrapperClassname,
									})}
								>
									<motion.input
										ref={mergeRefs([ref, animationRef])}
										form={formId}
										className={cn({
											[inputBaseClassName]: true,
											[inputBorder]: true,
											"rounded-r": !rightGroup,
											[`border-2 focus:border-zd-red-500 ${ZD_ERRORS.TEXT} ${ZD_ERRORS.BORDER} focus:ring-red-500/70`]:
												_inError && displaysErrors,
											"bg-gray-200": disabled,
											[`focus:outline-none focus:ring-2 focus:ring-brandDark-${brand} focus:border-transparent `]: true,
											[inputClassName]: !!inputClassName,
										})}
										{..._inputProps}
										{...inputProps}
										{...animationProps}
										onFocus={() => {
											triggerAnimation();
										}}
									/>
									{rightGroup && (
										<div className={"flex-shrink"}>{rightGroup}</div>
									)}
									{loading && (
										<div
											className={
												"absolute left-0 ml-4 flex h-full items-center"
											}
										>
											<ThreeDotsPulse
												overrideColor={"bg-gray-300"}
												size={"w-2 h-2"}
											/>
										</div>
									)}
									{displaysErrors && (
										<ErrorMessage
											stringContext={stringContext}
											translation={translation}
											elementInError={_inError}
											erroredElementMsg={_errorElement}
										/>
									)}
								</div>
							) : (
								<div className="relative">
									<motion.textarea
										form={formId}
										ref={mergeRefs([ref, animationRef])}
										className={cn({
											[inputBaseClassName]: true,
											[inputBorder]: true,
											"block w-full appearance-none rounded focus:outline-none": true,
											[`border p-1 focus:border-zd-red-500 ${ZD_ERRORS.BORDER} ${ZD_ERRORS.TEXT}`]:
												_inError && displaysErrors,
											"bg-gray-200": disabled,
											[`focus:outline-none focus:ring-2 focus:ring-brandDark-${brand} focus:border-transparent`]: true,
											[inputClassName]: !!inputClassName,
										})}
										autoComplete="xsdfs2"
										name={name}
										{..._inputProps}
										{...inputProps}
										{...animationProps}
										onFocus={() => {
											triggerAnimation();

											if (isFirstFocus.current) {
												// triggerAnimation();
												inputProps?.onFocus?.();
												isFirstFocus.current = false;
											}
										}}
									/>
									{displaysErrors && (
										<ErrorMessage
											stringContext={stringContext}
											translation={translation}
											elementInError={_inError}
											erroredElementMsg={_errorElement}
										/>
									)}
								</div>
							)}
						</>
						{validatedInputEl}
						{afterDescription && (
							<div className={"description text-sm"}>{afterDescription}</div>
						)}
					</div>
				);
			}}
		</TriggerAnimation>
	);
};
